import React from "react";
import ProdLayout from "../../prodlayout";
import pi1 from "./p1.jpg";
import pi2 from "./p2.jpg";
import pi3 from "./p3.jpg";
import pi4 from "./p4.jpg";
const data = {
  images: [pi1, pi2, pi3, pi4],
  badge: "Solid",
  sku: "Bk09",
  availability: "In stock",
  title: "OCSO Solid Men's Joggers BK09",
  mrpPrice: "1199",
  sellPrice: "699",
  disc:
    "OCSO Men's joggers with A pair of black melange knitted mid-rise look, has an elasticated waistband with an inner drawstring fastening, two pockets Engineered with Swift Dri technology",
};
export default () => {
  return <ProdLayout data={data} />;
};
